require("@popperjs/core/dist/umd/popper.min");
require("bootstrap/dist/css/bootstrap.min.css")
require("bootstrap/dist/js/bootstrap.min")

const React = require("react")


// Logs when the client route changes
// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   console.log("new pathname", location.pathname)
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }

exports.onPreRouteUpdate = ({ location, prevLocation }) => {

 if(!localStorage.getItem('tracking_first_url')){

   localStorage.setItem('tracking_first_url', location.href)
   localStorage.setItem('tracking_first_path', location.pathname)
   localStorage.setItem('tracking_referrer', document.referrer)
   //campaign GA
   if (!localStorage.getItem('utm_source')) {
     const search = location.search;
     let result = [];

     if (search && search.includes('utm_source=')) {
       search.substr(1).split('&').forEach(function (item) {
         let tmp = item.split('=');
         result.push(tmp);
       });
     }

     result.forEach(el => { localStorage.setItem(el[0], el[1]);});
   }
 }
}